// Entry point for your Sass build
// app/assets/stylesheets/application.sass.scss
@tailwind base;
@tailwind components;
@tailwind utilities;
/*
 *= require _font-awesome
 */
// Mixins
@import "mixins/media";

// Configuration
@import "config/variables";
@import "config/reset";

// Components
@import "components/nav";
@import "components/type_ahead";
@import "components/form_display";
@import "components/btn";
@import "components/error_message";
@import "components/form";
@import "components/visually_hidden";
@import "components/quote";
@import "components/variations";
@import "components/variation_template_items";
@import "components/variation_template";
@import "components/map_results";
@import "components/feed_box";
@import "components/map-canvas";
@import "components/task_list";
@import "components/image-nav";
@import "components/attachment_viewer";
// Layouts
@import "layouts/container";
@import "layouts/header";
@import "layouts/body";
@import "layouts/footer";
@import "layouts/show_details";
@import "layouts/button-area";
@import "layouts/index-header";
@import "layouts/left-menu";
@import "layouts/panel";
@import "layouts/login";
@import "layouts/calendar";
