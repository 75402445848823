.messaging-area{
  height: 100%;

}
.feed-box{
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  min-width:0;

  &--feed-footer{
    background-color: #efefef;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    &--footer-item-disabled{
      background-color:#efefef;
      color: #999;
      padding: 3px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 1.5rem;
      border-radius: var(--border-radius);
      border: 1px solid white;
    }
    &--footer-item{
      background-color:#25cfa1;
      color: #145c49;
      padding: 3px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 1.5rem;
      border-radius: var(--border-radius);
      border: 1px solid white;

    }
    &--send-menu{
      align-self: flex-end;

    }
    &--actions-menu{
      flex: 1 1 auto;

      button{


      }
    }
  }
  &--footer-items{

  }
  &--feed-form{
    height: 100%;
    padding: 10px 10px 10px 30px;
  }
  &--feed{
    flex: 1 1 auto;
    overflow: auto;
    height: 0;
    min-height: 0;
    align-self: stretch;
    flex-direction: column;
  }
  &--feed-entry{

    display: flex;
    flex-direction: column;
    background-color: white;
    height: 200px;
    border: 1px solid silver;

    border-radius: var(--border-radius);
    margin-bottom: var(--space-m)
  }


  &--feed-item{
    display: flex;
    margin-bottom: 15px;

    &--from-item{
      margin-right: 10px;
    }
    &--from-icon{
      border: 1px solid silver;
      border-radius: var(--border-radius);
      background-color: white;
      padding: 5px;
      height: 100%;
      width: 50px;
    }
    &--from-name{
      display: flex;
    }
    &--username{
      margin-right: 5px;
      font-weight: 700;
    }
    &--message-time{
      font-size: .9rem;
      margin-top: 3px;
      color: #999;
    }
    &--message-item{
      flex: 1 1 auto;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &--from-message{

    }
  }
}