.fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
  float: right;
}
.fc-dayGrid-view .fc-body .fc-row {
  min-height: 5rem;
}
.fc-daygrid-day-events{
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 5rem;
}
.fc.fc-theme-standard .fc-highlight{
  background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));
}


.fc-highlight{
  background-color: #efefef;
 }
.fc {
  font-size: 1rem;
  &.fc-theme-standard td, &.fc-theme-standard th, &.fc-theme-standard .fc-scrollgrid {
    border-color: lighten($table-border-color,2%) !important;
  }
  .fc-h-event {
    border: none;
  }
  .fc-col-header-cell.fc-day  {
    text-align: left;
    padding: .75rem 1rem;
    .fc-col-header-cell-cushion {
      padding: 0;
      font-size: 1rem;
      font-weight: 500;
      color: $gray-600;
    }
  }
  .fc-day .fc-daygrid-day-top {
    flex-direction: row;
    .fc-daygrid-day-number {

      font-weight: $font-weight-bold;
      color: lighten($body-color,10%);
      font-size: 1rem;
      padding: .5rem 1rem;
    }
  }
  .fc-daygrid-day.fc-day-today{
    background-color: inherit !important;
    .fc-daygrid-day-top {
      .fc-daygrid-day-number {
        color: $orange;
      }
    }
  }
  .fc-event {
    margin: 2px 9px 1px !important;
  }
  .fc-event-main {
    display:flex;
      padding: 0;
      
      border-radius: $border-radius-sm;
      border: 0;
      font-size: 1rem;
      .fc-event-title {
          padding: .4rem .5rem;
          display: block;
          color: $white;
          @include text-truncate();
          font-weight: $font-weight-bold;
      }

      .fc-time {
          float: left;
          background: rgba($black,0.2);
          padding: 2px 6px;
          margin: 0 0 0 -1px;
      }
  }
}
