.image-nav{
  display: flex;
  align-items:center;
  justify-content: end;
  &--title{
    font-size: 1.1rem;
    color: #666;
    margin-right: 10px;
  }
  &--buttons{
    button{

      font-size: 1.2rem;
      background-color: white;
      border: 1px solid silver;
      padding: 4px;
      border-radius: var(--border-radius);
    }
    button:last-child{

      margin-left:5px;
    }
  }
}