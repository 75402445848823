.attachment-viewer{
  &--image{
    width: 300px;
    margin: auto;
  }
}
.no-image{
  width: 300px;
  color: #ddd;
  font-size: 300px;
  margin: auto;

}