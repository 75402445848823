// app/assets/stylesheets/components/_form.scss
.file{
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 2.5rem;
  width: 100%

}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: "Browse";
  height: 2.5rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #eee;
  border: 0.075rem solid #ddd;
  border-radius: 0 0.25rem 0.25rem 0;
}
.file-custom:after {


}
.file-details{
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  padding: 5px;
}
.file-details div label{
  font-weight: 700;
  min-width: 200px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.file-custom{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.5;
    color: #555;
    background-color: #fff;
    border: 0.075rem solid #ddd;
    border-radius: 0.25rem;

  }
.form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: var(--space-xs);

  &--group {
    flex: 1;
    margin-bottom:  var(--space-s);
  }
  &--label{
    display:block;
    font-weight: 800;
  }
  &--file-upload{
    background-color: white;
  }
  &--input_group{
    display:flex;
    &--input_prefix{
      border:1px solid silver;
      padding:5px 8px;
      background-color: #efefef;
      display:inline;
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &--input {
      display: block;
      width: 100%;
      max-width: 100%;
      padding: var(--space-xxs) var(--space-xs);
      border: var(--border);
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      outline: none;
      transition: box-shadow 250ms;
      margin-bottom:  var(--space-s);
      &:focus {
        box-shadow: 0 0 0 2px var(--color-glint);
      }

      &--invalid {
        border: 1px solid var(--color-error);
      }
    }
  }
  &--invalid-feedback{
    padding: 3px;
    color:var(--color-error);

  }
  &--input {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: var(--space-xxs) var(--space-xs);
    border: var(--border);
    border-radius: var(--border-radius);
    outline: none;
    transition: box-shadow 250ms;

    &:focus {
      box-shadow: 0 0 0 2px var(--color-glint);
    }

    &--invalid {
      border-color: var(--color-error);
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    &--valid {
      border-color: #198754;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}
.data-view-display{
  font-weight: 700;
}