.main-body{
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}
.main-body-container{
  flex: 1 1 auto;
  padding: var(--content-padding);
  height: 100%;
  align-self: stretch;
}
.main-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
}