@import "./colors";
@import "./breakpoints";

@mixin roundedCorners {
  border-radius: 3px;
}

@mixin boxShadow {
  box-shadow: 3px 3px 6px $color-shadowGrey;
}

@mixin shadowedBox {
  @include boxShadow;
  background-color: $color-lightGrey;
  border: 1px $color-darkBlue solid;
}

@mixin gridWithStretch {
  display: grid;
  align-items: stretch;
  justify-items: stretch;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-col {
  display: flex;
  flex-direction: column;
}

@mixin noWrapWithEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin style-header {
  color: $color-darkBlue;
  font-family: $font-sansSerif;
  font-weight: 500;
}

@mixin style-h1 {
  @include style-header;
  font-weight: 800;
  font-size: 60px;

  @media only screen and (max-width: $large) {
    font-size: 36px;
  }

  @media only screen and (max-width: $medium) {
    font-size: 24px;
  }
}

@mixin style-h2 {
  @include style-header;
  font-size: 48px;

  @media only screen and (max-width: $large) {
    font-size: 24px;
  }

  @media only screen and (max-width: $medium) {
    font-size: 18px;
  }
}

@mixin style-h3 {
  @include style-header;
  font-size: 36px;

  @media only screen and (max-width: $large) {
    font-size: 18px;
  }

  @media only screen and (max-width: $medium) {
    font-size: 16px;
  }
}

@mixin style-h4 {
  @include style-header;
  font-size: 24px;

  @media only screen and (max-width: 1300px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 14px;
  }
}

@mixin style-h5 {
  @include style-header;
  font-size: 18px;

  @media only screen and (max-width: 1300px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 14px;
  }
}

@mixin lineHeights-h4 {
  line-height: 28px;

  @media only screen and (max-width: 1300px) {
    line-height: 21px;
  }

  @media only screen and (max-width: 1100px) {
    line-height: 16px;
  }
}
