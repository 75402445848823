.button-area{
  display:flex;
  margin-bottom: var(--space-s);
  button,a{
    margin-right: var(--space-s);
  }
}
.data-action-row{
  margin-top: var(--space-s);
  margin-bottom: var(--space-s);
}