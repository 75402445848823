.map-results{
  background-color: white;
  margin-right: 10px;
  overflow: auto;
  &--item{
    background-color: white;
    border-bottom: 1px solid #BBB;
    margin-top: var(--space-m);
    margin-bottom: var(--space-m);
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
  }
}