@import '../../../styles/config.scss';

.tabWrapper {
    background-color: $color-lightGrey;
    border: 1px solid $color-darkGrey;
    border-bottom: none;
    @include roundedCorners;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    @include flex-col;
    justify-content: center;
}

.tabWrapper:hover {
    background-color: $color-paleBlue;
    cursor: pointer;
}

.isSelected,
.isSelected:hover {
    background-color: $color-darkBlue;

    p {
        color: white !important;
    }
}

.isDisabled,
.isDisabled:hover {
    background-color: $color-lightGrey;

    p {
        color: $color-medGrey;
    }
}

.tabText {
    @include style-h5;

    font-weight: normal;
    text-align: center;
    margin: $padding-tab;
}
