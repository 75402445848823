.form_display{
  padding: var(--space-l);
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  &__navs{
    margin-bottom: var(--space-l);
  }
  &__field{
    margin-bottom: var(--space-l);
    &__label{
      font-weight: bold;
    }
    &__value{

    }
  }
}