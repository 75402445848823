// app/assets/stylesheets/layouts/_header.scss

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: var(--space-l);
  height: 100px;
  flex-shrink: 1;
  flex-basis: auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  background-color: white;
  .toggle-menu-m-d{
    width: 75px;
    flex: 0 1 auto;
  }
  @include media(tabletAndUp) {
    margin-bottom: var(--space-s);


  }
  @include media(lg) {
    .toggle-menu-m-d{
      display: none;
    }
  }

}