.container {
  width: 100%;
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
  margin-left: auto;
  margin-right: auto;

  @include media(tabletAndUp) {
    padding-right: var(--space-m);
    padding-left: var(--space-m);
    max-width: 80rem;
  }
}
.content-layout{
  display: flex;
  flex: 1 1 auto;
  align-self: stretch;
}
.main{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.middle-container{
  display: flex;
  flex: 1 1 auto;
  align-self: stretch;
  min-height: 90%;
}
.display-row{
  display: flex;
  justify-content: space-between;
}
.display-column{
  flex: 1 1 auto;
  margin-right: 20px;
}

.display-column:last-child{
  margin-right: 0;
}