.task-list{
  padding-top: 15px;
  width: 100%;
}

.task-item{
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  padding-left: 0.75rem;
  position: relative;
  &--type-1{
    background-color: #2dce89;
    position: absolute;
    width: 3px;
    height: 100%;
    top:0;
    left: 0;
    border-radius: var(--border-radius);
  }
  &--def{
    flex: 1 1 auto;


  }

  &--header{
    display: flex;
    &--name{
      font-size: 1.1rem;
      margin-right: 10px;
    }
    &--date{
      margin-top: 3px;
      color: #666;
    }

  }
  &--content{
    font-weight: 700;
    font-size: 1.1rem;

  }
  &--check-not-complete{
    button{
      height: 23px;
      width: 23px;
      background-color: white;
      border: 1px solid silver;
      cursor: pointer;
      border-radius: var(--border-radius);
    }

  }
  &--check{
    button{
      cursor: pointer;
      height: 25px;
      background-color: #2dce89;
      color: white;
      border: white;
      padding: 3px;
      border-radius: var(--border-radius);
    }

  }

}