.variations{

  &__header{
    font-size: var(--font-size-xl);
    font-weight: bold;
  }
  &__items{

    margin-block-start: var(--space-l);
    &__item{
      display:flex;
      background-color:var(--color-white);
      border-radius: var(--border-radius);
      padding: var(--space-l);
      margin-block-end: var(--space-l);
      &__details{
        flex-grow: 1;
      }
      &__actions{
        text-align: right;
        button{

        }
        a{
          margin-block-end: var(--space-s);
        }
      }
    }
  }
}
