.footer{

  width: 100%;

  align-self: end;
  flex: 1 1 auto;
  color: #333;
  background-color: white;
  padding: var(--space-l);
  margin-top: 15px;
  &--text{
    color: #999;
  }
}