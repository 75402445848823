.show_details{
  display:flex;
  flex-basis: auto;
  align-items: flex-start;
  & > *{
    flex: 1 1  5ch;

    margin-right: 10px;

  }
}