.nav{
  &__main{
    background-color:white;
    padding:var(--space-l);
    font-size: var(--font-size-l);
    &__items{
      &__item{
        display:inline-block;
        margin-right: var(--space-l);
      }
    }
  }
}