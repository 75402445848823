@import "../../../styles/config.scss";

.tabbedView {
  @include gridWithStretch;
  grid:
    "tabs" auto "tabDetail" 1fr
    / 1fr;
  height: 100%;
}

.tabArea {
  grid-area: tabs;
}

.tabDetailArea {
  grid-area: tabDetail;
  @include shadowedBox;
  overflow: auto;
}
