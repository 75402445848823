.panel{
  border: var(--border);
  padding: var(--space-s);
  border-radius: var(--border-radius);
  background-color: var(--color-white);
  margin-top: var(--space-m);
  display:flex;
  flex-direction: column;
  &--md-sm{
    max-width: 45%;
    min-width: 35%;

    max-height: 35%;

  }
}
.full-tab-view{
  height: 100%;
}
.full-height-tab{
  height: 100%;
}
.p-tabview-panels{
  height: 93%;

}
.categories-panel{
  max-width: 350px;

}