.left-menu{
  height: 100%;
  .p-slidemenu{
    height: 100%;
  }
  &--wrapper{
    background-color: #333;
  }
  &--header{
    color:var(--color-white-muted);
    font-size: 1.1em;
    margin-bottom: 8px;
  }
  &--action{
    color: var(--color-white-muted);
    cursor: pointer;
    margin-bottom: 5px;
    width:100%;
    display: flex;
  }
  &--link-label{
    flex: 1 1 auto;
  }
  &--link-icon{
    flex-shrink: 1;
  }
  &--dropdown-area{
    display:flex;

  }
  &--icon-trigger{
    flex-shrink: 1;
    color: var(--color-white-muted);
  }
  &--item{
    color: var(--color-white-muted);
    border-bottom: 1px solid var(--color-white-muted);
    padding-top: 5px;
    padding-bottom: 5px;
    a{
      color:var(--color-white-muted);
    }
  }
}
