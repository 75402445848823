$color-darkBlue: #2f4a5f;
$color-medBlue: #3d607b;
$color-lightBlue: #507da0;
$color-lighterBlue: #c2d1de;
$color-paleBlue: #cad8e3;

$color-darkGrey: #707070;
$color-medGrey: #b2b2b2;
$color-softGrey: #ddd;
$color-lightGrey: #f5f5f5;

$color-shadowGrey: rgba(black, 0.16);
