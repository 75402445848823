.typeahead{
  display: flex;
  flex-direction: column;
  &--container{

    width: 100%;

  }
  &--selected{
    display: flex;
    &--value{
      flex: 1 1 auto;
    }
  }
  &--remove-button{
    background: none;
    border: none;
    color: var(--color-primary)
  }
  .typeahead-q{

    width: 100%;
    overflow: auto;

    &--col-text{
      overflow: hidden;
      text-wrap: none;
    }
    &--grid{
      height: 200px;


      position: absolute;
      overflow: auto;
      float: left;
      background-color: white;
      border: 1px solid #efefef;
      width: 95%;
      z-index: 10;
      padding: 5px;
    }
    &--head-col{
      font-weight: 700;
      border-bottom: 1px solid #efefef;
    }
    &--header{
      display:flex;
    }
    &--col{
      flex:1 1 auto;

    }
    &--row{

    }
    &--add-area{
      margin-top: 10px;
    }
    &--row-col{
      line-height: 2rem;
      height: 2rem;
      border-bottom: 1px solid #efefef;
      button{
        background:none;
        border: none;
        color: var(--color-primary)

      }
    }
  }
}