// app/assets/stylesheets/mixins/_media.scss

@mixin media($query) {
  @if $query == tabletAndUp {
    @media (min-width: 50rem) { @content; }
  }
  @if $query == lg {
    @media (min-width: 1200px) { @content; }
  }

}